import { Helmet } from 'react-helmet'
import UseBottomNav from "components/Navigation/BottomNav"
import UseChatThreads from "components/ChatCards/ChatThreads"
import UseNewThreads from "components/ChatCards/NewThreads"
import UseChatHeader from "components/ChatCards/ChatHeader"
import UseChatBubble from "components/ChatCards/ChatBubbles"
import UseChatFooter from "components/ChatCards/ChatFooter"

const Messages = () => {
	
	return (
		<>
			<Helmet>
				<title>bingeAsk - Find friends to watch movie together!</title>
			</Helmet>
			<div className="mt-4">
                <div className="container mt-4" style={{height: '90vh'}}>
					<h2 className="text-center text-s">Coming Soon</h2>
					{/* <div className="row">
						<div className="col-3 pr-0">
							<div className="card bg-dark border-0" style={{height: '82vh'}}>
								<div className="card-body p-3">
									<div className="">
										<h5 className="text-light-gray font-weight-500 mb-3">Chats</h5>
										<UseChatThreads />
									</div>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="card bg-dark border-0" style={{height: '82vh'}}>
								<div className="card-header bg-dark pb-0 border-bottom border-dark">
									<UseChatHeader />
								</div>
								<div className="card-body pt-2" style={{height: '82vh', overflow: 'auto'}}>
									<UseChatBubble />
								</div>
								<div className="card-footer bg-dark">
									<UseChatFooter />
								</div>
							</div>
						</div>
						<div className="col-3 pl-0">
							<div className="card bg-dark border-0" style={{height: '82vh'}}>
								<div className="card-body p-3">
									<div className="">
										<h5 className="text-light-gray font-weight-500 mb-3">Start New Threads</h5>
										<UseNewThreads />
									</div>
								</div>
							</div>
						</div>
					</div> */}
                </div>
                <UseBottomNav page="messages" />
			</div>
		</>
	)
}

export default Messages
